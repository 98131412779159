import React from 'react'

const useDynamicScript = attrs => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    Object.keys(attrs).forEach(key => {
      script.setAttribute(key, attrs[key])
    })

    if (document.head.firstChild) {
      document.head.insertBefore(script, document.head.firstChild)
    } else {
      document.head.appendChild(script)
    }

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script)
      }
    }
  }, [attrs])
}

export default useDynamicScript
