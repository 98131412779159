import * as Sentry from '@sentry/react'
import getEnv from '@utils/getEnv'
import isSSR from '@utils/isSSR'
import omitBy from 'lodash.omitby'
import { useEffect } from 'react'

const REACT_APP_PENDO_KEY = getEnv('REACT_APP_PENDO_KEY')
const PENDO_API_SRC = `https://cdn.pendo.io/agent/static/${REACT_APP_PENDO_KEY}/pendo.js`

let isScriptLoaded = false

const getMetadata = user => {
  const currentCount = parseInt(localStorage.getItem('pendo_count') || '0', 10)
  const updateCount = currentCount + 1
  localStorage.setItem('pendo_count', updateCount.toString())

  const metadata = {
    _type: user?.Type ?? null,
    _registered: user?.id ? true : null,
    _school: user?.school?.name ?? null,
    _name: user?.firstName ? true : null,
    _grad_year: user?.graduationYear ?? null,
    _major: user?.major ?? null,
    _metadata_updated: true,
    _metadata_update_count: updateCount,
    _metadata_last_updated: new Date().toISOString()
  }

  return omitBy(metadata, e => e === null)
}

const loadScript = callback => {
  if (isSSR() || !REACT_APP_PENDO_KEY) return
  const existingScript = document.getElementById('pendo-script')

  if (!existingScript) {
    const script = document.createElement('script')

    script.src = PENDO_API_SRC
    script.id = 'pendo-script'

    document.body.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }

    if (existingScript && callback) callback()
  }
}

const initialOptions = {
  callback: null,
  dependencies: []
}

const useLoadScript = async (options = initialOptions) => {
  const { callback, dependencies } = options

  await useEffect(() => {
    if (!isScriptLoaded) {
      loadScript(callback)
      isScriptLoaded = true
    } else {
      callback()
    }
  }, [dependencies])
}

const identify = user => {
  const metadata = getMetadata(user)

  window.pendo.updateOptions({
    visitor: metadata
  })
}

const updateSchool = schoolName => {
  if (window?.pendo) {
    const currentCount = parseInt(localStorage.getItem('pendo_count') || '0', 10)
    const updateCount = currentCount + 1
    localStorage.setItem('pendo_count', updateCount.toString())

    window.pendo.updateOptions({
      visitor: {
        _school: schoolName,
        _metadata_update_count: updateCount,
        _metadata_last_updated: new Date().toISOString()
      }
    })
  }
}

const track = ({ event, options }) => {
  try {
    window.pendo.track(event, Object.assign(options))
  } catch (error) {
    Sentry.captureException(error)
  }
}

const initialize = user => {
  if (!window.pendo || isSSR()) return
  /**
   * VISITOR-UNIQUE-ID / ACCOUNT-UNIQUE-ID specifically tells Pendo to
   * auto-generate unique ids for users and handle data mapping
   */
  window.pendo.initialize({
    visitor: {
      id: 'VISITOR-UNIQUE-ID'
    },
    account: {
      id: 'ACCOUNT-UNIQUE-ID'
    }
  })

  identify(user)
}

export default { useLoadScript, loadScript, initialize, track, updateSchool }
