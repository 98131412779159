import PropTypes from 'prop-types'

const TRACKING_EVENT = {
  PROF_LOAD_MORE: 'PROF:LoadMore',
  PROF_RATING_HELPFUL: 'PROF:Useful',
  PROF_RATING_NOT_HELPFUL: 'PROF:NotUseful',
  PROF_RATE_BUTTON: 'PROF:Rate_top',
  PROF_RATE_FIRST_TEXT: 'PROF:rate_first_text',
  PROF_RATE_BUTTON_TOP: 'PROF:Rate_first_top',
  PROF_RATE_BUTTON_CENTER: 'PROF:Rate_first_center',
  PROF_RATE_BUTTON_BOTTOM: 'PROF:Rate_first_bottom',
  PROF_DEPARTMENT_LINK: 'PROF:Department', // User clicks the department filter on professor profiles
  SCHOOL_RATE_BUTTON_TOP: 'SCHOOL: Rate_top',
  SCHOOL_RATE_BUTTON_BOTTOM: 'SCHOOL: Rate_bottom',
  SCHOOL_LOAD_MORE: 'SCHOOL:LoadMore',
  PROF_PAGE_INFO: 'PROF:ratings:info',
  TEACHER_CARD_LINK: 'TeacherCard:ProfileClick', // User clicks a TeacherCard to go to that teacher's profile page
  TEACHER_CARD_SAVED_PROF_ADD: 'TeacherCard:SavedProf:Add', // User saves a teacher from the TeacherCard
  TEACHER_CARD_SAVED_PROF_REMOVE: 'TeacherCard:SavedProf:Remove', // User unsaves a teacher from the TeacherCard
  PROFILE_PAGE_SAVED_PROF_ADD: 'PROF:SavedProf:Add', // User saves a teacher from the teacher's profile
  PROFILE_PAGE_SAVED_PROF_REMOVE: 'PROF:SavedProf:Remove', // User unsaves a teacher from the teacher's profile
  SAVED_PROF_REGWALL_OPEN: 'RegWall:Open:Prof:SaveProf',
  RATINGS_FILTER_DROPDOWN_OPEN_COURSE: 'PROF:FilterRatings:Course:Opened',
  RATINGS_FILTER_CLEAR_COURSE: 'PROF:FilterRatings:Course:Clear',
  RATINGS_FILTER_SELECT_COURSE: 'PROF:FilterRatings:Course:Filter',
  SIMILAR_PROF_CLICK: 'RelatedProfessorsClicked',
  SIGN_UP_CLICK: 'SignupClick',
  LOGIN_CLICK: 'LogInSignUp',
  TYPEAHEAD_SELECT_PROF: 'GLOBAL_NAV:findProfessorHeaderTypeaheadClick',
  TYPEAHEAD_SELECT_SCHOOL: 'GLOBAL_NAV:findSchoolHeaderTypeaheadClick',
  CCPA_FOOTER_VIEWED: 'CCPAFooter:Viewed',
  CCPA_FOOTER_CLOSE: 'CCPAFooter:Close',
  CCPA_FOOTER_DNS: 'CCPAFooter:DNS',
  CCPA_FOOTER_PRIVACY: 'CCPAFooter:Privacy',
  CCPA_PAGE_OPT_OUT: 'CCPAPage:OptOut',
  CCPA_PAGE_OPT_IN: 'CCPAPage:OptIn',
  FLAG_RATING: 'PROF:ReportRating',
  SCHOOL_FLAG_RATING: 'SCHOOL:ReportRating',
  REPLY_CLICK: 'PROF:Reply:Click', // Prof click's reply to start a note
  REPLY_SUBMIT_NEW: 'PROF:Reply:Submit:New', // Prof clicks submit on the note editor in "new note" mode
  REPLY_CANCEL_NEW: 'PROF:Reply:Cancel:New', // Prof clicks cancel on the note editor in "new note" mode
  REPLY_ADDED_NEW: 'PROF:Reply:Added:New', // New Prof note is actually submitted (no form errors etc)
  REPLY_DELETE: 'PROF:Reply:Delete', // Prof clicks delete on an existing note
  REPLY_EDIT: 'PROF:Reply:Edit', // Prof clicks edit on an existing note
  REPLY_SUBMIT_EDIT: 'PROF:Reply:Submit:Edit', // Prof clicks submit on the note editor in "edit existing note" mode
  REPLY_CANCEL_EDIT: 'PROF:Reply:Cancel:Edit', // Prof clicks cancel on the note editor in "edit existing note" mode
  REPLY_ADDED_EDIT: 'PROF:Reply:Added:Edit', // Edited Prof note is actually submitted (no form errors etc)
  REPLY_FLAG: 'PROF:Reply:Flag', // User clicks the report flag on a prof note
  REPLY_HELPFUL: 'PROF:Reply:Useful', // User clicks thumbs up on a prof note
  REPLY_NOT_HELPFUL: 'PROF:Reply:NotUseful', // User clicks thumbs down on a prof note
  REGWALL_OPEN_PROF: 'RegWall:Open:Prof:Thumbs', // regwall opened (from thumbs up/ down on prof page)
  REGWALL_OPEN_PROF_SL: 'RegWall:Open:Prof:Softlock', // regwall opened (from a loggedout user attempting to rate a soft-locked prof)
  REGWALL_DISMISS_PROF: 'RegWall:Dismiss:Prof:Thumbs', // - regwall closed
  REGWALL_LOGIN_CLICK_PROF: 'RegWall:ClickLogin:Prof:Thumbs', // login link clicked
  REGWALL_SIGNUP_CLICK_PROF: 'RegWall:ClickSignup:Prof:Thumbs', // sign up button clicked
  CONFIRM_EMAIL_WALL_OPEN_PROF: 'ConfirmEmailWall:Open:Prof:Thumbs', // Confirm Email CTA opened (from unconfirmed user clicking thumbs up/ down on prof page)
  CONFIRM_EMAIL_WALL_OPEN: 'ConfirmEmailWall:Open:Prof:SaveProf', // Confirm Email CTA opened (from unconfirmed user attempting to save a professor)
  SIGNUPWALL_DETAILS_SKIPPED: 'SignupWall:ExtraDetails:Skip', // In signup flow, user opts not to provide optional additional details
  SIGNUPWALL_DETAILS_SUBMITTED: 'SignupWall:ExtraDetails:FormSubmitted', // In signup flow, user does submit optional additional details
  HEADER_TOGGLE_TO_PROF: 'Header:SearchCategory:ToggledTo:Teacher', // In header, user changed search category by selecting "Professors" in the category dropdown
  HEADER_TOGGLE_TO_SCHOOL: 'Header:SearchCategory:ToggledTo:School', // In header, user changed search category by selecting "Schools" in the category dropdown
  HERO_TOGGLE_TO_TEACHER: 'Homepage:SearchCategory:ToggledTo:Teacher', // On homepage, user toggled the search category in the hero to TEACHER (clicked "I'd like to look up a prof by name")
  HERO_TOGGLE_TO_SCHOOL: 'Homepage:SearchCategory:ToggledTo:School', // On homepage, user toggled the search category in the hero to SCHOOL
  HERO_SAVED_SCHOOL_LINK: 'Homepage:HeroSavedSearch:Click:SchoolLink', // On homepage, user previously set their school and we saved it as cookie and now they are clicking the saved school link on the homepage hero
  HERO_RESULTS_SCHOOL_LINK: 'Homepage:HeroSearchSuggestions:Click:SchoolLink', // In suggested results of school field, user clicks the schoolname link sending them to that schools page
  HEADER_UNSET_SCHOOL: 'Header:UnsetSchool' // User sets a new set school by clicking the name of the previously set school        Note: This has not been implemented yet
}

export const PENDO_TRACKING_EVENT = {
  TRACK_COMPARE_SCHOOLS: 'compare_schools',
  TRACK_COMPARE_PROFESSORS: 'compare_professors',
  TRACK_PROFESSOR_SCHOOL: 'professors_school',
  TRACK_SCHOOL_INFO: 'school_info'
}

export const TRACKING_ACTION = {
  RATING_SUCCESS: 'Rating_success',
  RATING_ERROR: 'Rating_error'
}

export const TRACKING_CATEGORY = {
  PROF_RATING: 'Prof_Rating',
  SCHOOL_RATING: 'School_Rating'
}

export const TrackingEventTypes = PropTypes.oneOf(Object.values(TRACKING_EVENT))

export default TRACKING_EVENT
