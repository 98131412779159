import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import useCurrentRoute from '@hooks/useCurrentRoute'
import { useHistory } from 'react-router-dom'
import Button from '@components/Button'
import USERTYPES from '@enums/USERTYPES'
import useUserInfo from '@hooks/useUserInfo'
import ROUTE, { USER_ACCOUNT_ROUTE } from '@enums/ROUTE'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import Link, { LINK_TYPE } from '@components/Link'
import ModalButtonLink from '@components/ModalButtonLink'
import MODALS from '@enums/MODALS'
import useGlobalContext from '@hooks/useGlobalContext'

export const HeaderButtonStyles = css`
  border-radius: 20px;
  cursor: pointer;
  margin-left: 16px;
  padding: 8px 16px;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.color.gray10};
  }
`

const loginMenuStyles = css`
  color: white;
  display: block;
  font-weight: ${props => props.theme.fontWeight.bold};
  height: 100%;
  text-decoration: none;

  ${HeaderButtonStyles}

  &:last-of-type {
    border: 1px solid white;
    margin: 0;

    &:hover {
      /* change to black so that it still takes up the same amount of space while hovering */
      border: 1px solid transparent;
    }
  }
`

export const LoginLink = styled(ModalButtonLink)`
  background: transparent;

  ${loginMenuStyles}

  &:last-of-type {
    margin-left: 16px;
  }
`

export const SignUpLink = styled(ModalButtonLink)`
  ${loginMenuStyles};
`

export const UserMenuWrapper = styled.div`
  display: flex;
`

export const AccountMenu = styled.div`
  align-items: flex-start;
  background: white;
  border: solid 1px ${props => props.theme.color.gray2};
  border-radius: 8px;
  box-shadow: 2px -1px 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 175px;
  position: absolute;
  right: 8px;
  text-align: left;
`

export const MyAccountButton = styled.button`
  background: none;
  border: 0;
  color: white;
  display: block;
  font-weight: ${props => props.theme.fontWeight.bold};
  height: 100%;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;

  ${HeaderButtonStyles}
`

const AccountListItemStyles = css`
  font-weight: ${props => props.theme.fontWeight.bold};
  height: 100%;
  padding: 12px 16px;
  text-decoration: none;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.color.darkMainBlue};
    color: white;
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`

export const AccountListButton = styled(Button)`
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  outline: none;
  text-align: left;

  ${AccountListItemStyles}
`

export const AccountListItem = styled(Link)`
  ${AccountListItemStyles}
`

export const STUDENT_MENU_ITEMS = [
  {
    label: 'Profile',
    link: USER_ACCOUNT_ROUTE.PROFILE,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'Account Settings',
    link: USER_ACCOUNT_ROUTE.SETTINGS,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'Your Ratings',
    link: USER_ACCOUNT_ROUTE.RATINGS,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'Saved Professors',
    link: USER_ACCOUNT_ROUTE.SAVED_PROFESSORS,
    type: LINK_TYPE.INTERNAL
  }
]

export const TEACHER_MENU_ITEMS = [
  {
    label: 'Profile',
    link: ROUTE.USER_ACCOUNT,
    type: LINK_TYPE.INTERNAL
  }
]

export const getUserMenuItems = userType => {
  return userType === USERTYPES.STUDENT ? STUDENT_MENU_ITEMS : TEACHER_MENU_ITEMS
}

export default function UserMenu() {
  const userInfo = useUserInfo()
  const { logout } = useGlobalContext()
  const [isOpen, setIsOpen] = useState(false)
  const isLoggedIn = userInfo && !!userInfo.Email
  const currentRoute = useCurrentRoute()
  const history = useHistory()

  if (!isLoggedIn) {
    return (
      <UserMenuWrapper data-testid="LoginSignupButtons">
        <LoginLink trackingLabel={TRACKING_EVENT.LOGIN_CLICK} modal={MODALS.LOGIN} to={ROUTE.LOGIN}>
          Log In
        </LoginLink>
        <SignUpLink to={ROUTE.SIGN_UP} modal={MODALS.SIGN_UP_FORM}>
          Sign Up
        </SignUpLink>
      </UserMenuWrapper>
    )
  }

  const accountButtonText = userInfo.firstName ? `Hey, ${userInfo.firstName}` : 'My Account'

  return (
    <div>
      <MyAccountButton onClick={() => setIsOpen(!isOpen)} data-testid="AccountButton">
        {accountButtonText}
      </MyAccountButton>
      {/* Need to prevent mouse down on the account menu so that it doesn't remove focus from the Account Button */}
      {isOpen && (
        <AccountMenu>
          {getUserMenuItems(userInfo.Type).map(item => {
            return (
              <AccountListItem key={item.link} to={item.link} type={item.type}>
                {item.label}
              </AccountListItem>
            )
          })}
          <AccountListButton
            onClick={e => {
              e.preventDefault()
              setIsOpen(false)
              logout()
              if (currentRoute !== ROUTE.HOME) {
                history.push({ pathname: ROUTE.HOME, state: { toast: null } })
              }
            }}
          >
            Logout
          </AccountListButton>
        </AccountMenu>
      )}
    </div>
  )
}
