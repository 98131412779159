import isSSR from '@utils/isSSR'
import getEnv from '@utils/getEnv'

export const RECAPTCHA_SCRIPT_ID = 'recaptcha-script'
export const RECAPTCHA_EXECUTION_ERROR = 'execution-error'
const RECAPTCHA_KEY = getEnv('REACT_APP_RECAPTCHA_V3_KEY')
const ACTIONS = {
  VALIDATE_EMAIL: {
    action: 'VALIDATE_EMAIL'
  },
  RESET_PASSWORD: {
    action: 'RESET_PASSWORD'
  },
  ADD_RATING_PROFESSOR: {
    action: 'ADD_RATING_PROFESSOR'
  },
  ADD_RATING_SCHOOL: {
    action: 'ADD_RATING_SCHOOL'
  },
  FLAG_RATING: {
    action: 'FLAG_RATING'
  },
  ADD_NEW_CAMPUS: {
    action: 'ADD_CAMPUS'
  },
  ADD_NEW_PROFESSOR: {
    action: 'ADD_NEW_PROFESSOR'
  },
  SUBMIT_CORRECTION: {
    action: 'SUBMIT_CORRECTION'
  }
}

let loadTriggered = false
let loaded = false

function Load() {
  if (isSSR() || loadTriggered) return

  const script = document.createElement('script')
  script.id = RECAPTCHA_SCRIPT_ID
  script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
  script.addEventListener('load', () => {
    loaded = true
  })
  document.body.appendChild(script)
  loadTriggered = true
}

function Execute(action, callback) {
  if (!loaded) {
    callback(RECAPTCHA_EXECUTION_ERROR)
    return
  }
  global.grecaptcha.ready(() => {
    try {
      global.grecaptcha.execute(RECAPTCHA_KEY, action).then(token => {
        callback(`v3|${token}`)
      })
    } catch (error) {
      console.error(error)
      // in the event of an error, even if the token was invalid, we still want
      // the callback called so that hermione can provide an error message to
      // the frontend form. The callback requires a string, so:
      callback(RECAPTCHA_EXECUTION_ERROR)
    }
  })
}

function Remove() {
  // remove badge
  const nodeBadge = document.querySelector('.grecaptcha-badge')
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode)
  }

  // remove script
  const script = document.getElementById(RECAPTCHA_SCRIPT_ID)
  if (script) {
    script.remove()
  }
  loadTriggered = false
  loaded = false
}

export default {
  ACTIONS,
  Load,
  Execute,
  Remove
}
