import { INFINITE_TABOOLA_ANCHOR } from '@components/Ads/TaboolaAd'
import routeConfigs from '@src/config/routeConfig'
import React, { useState, useEffect, useRef } from 'react'
import { withRouter, matchPath } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'

import Footer from './Footer'

export const TABOOLA_INTERVAL = 100

const taboolaHasChildren = taboolaRef => {
  if (!taboolaRef.current) return false

  const taboola = taboolaRef.current

  return taboola && taboola.children && taboola.children.length > 0
}
const handleScroll = (setIsInTaboola, isInTaboola, taboolaRef) => {
  if (!taboolaHasChildren(taboolaRef)) return

  const isCurrentlyInTaboola = window.scrollY > taboolaRef.current.offsetTop

  if (isCurrentlyInTaboola !== isInTaboola) setIsInTaboola(isCurrentlyInTaboola)
}

const isTaboolaRoute = location => {
  const currentRoute = routeConfigs.find(rc => matchPath(location.pathname, rc))
  return (currentRoute && currentRoute.hasTaboolaFooter) || false
}

let interval
export const FooterTaboolaWrapper = ({ location }) => {
  const taboolaRef = useRef()

  const [isTaboolaOnPage, setIsTaboolaOnPage] = useState(isTaboolaRoute(location))
  const [isTaboolaPopulated, setIsTaboolaPopulated] = useState(false)
  const [isInTaboola, setIsInTaboola] = useState(false)

  useEffect(() => {
    interval = setInterval(() => {
      if (!taboolaRef.current) {
        taboolaRef.current = document.getElementById(INFINITE_TABOOLA_ANCHOR)
      }

      const taboolaChildren = taboolaHasChildren(taboolaRef)

      if (!isTaboolaPopulated && taboolaRef.current && taboolaChildren) {
        setIsTaboolaPopulated(true)
        clearInterval(interval)
      }
    }, TABOOLA_INTERVAL)

    // Find out if taboola should be on the page
    const taboolaRoute = isTaboolaRoute(location)
    if (taboolaRoute !== isTaboolaOnPage) {
      setIsTaboolaOnPage(taboolaRoute)
    }

    // If taboola section doesn't exist, we're on a page without it and footer
    // should always be shown, and be non-sticky.
    if (!taboolaRoute) return () => {}

    const callback = () => handleScroll(setIsInTaboola, isInTaboola, taboolaRef)
    window.addEventListener('scroll', callback)
    return () => {
      window.removeEventListener('scroll', callback)
      taboolaRef.current = null
    }
  }, [isInTaboola, location, isTaboolaPopulated])

  return (
    <React.Fragment>
      <Footer
        sticky={isTaboolaOnPage && isTaboolaPopulated}
        hidden={isTaboolaOnPage && !isInTaboola && isTaboolaPopulated}
      />
    </React.Fragment>
  )
}

FooterTaboolaWrapper.propTypes = {
  location: ReactRouterPropTypes.location
}

export default withRouter(FooterTaboolaWrapper)
