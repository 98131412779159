import { commitMutation as relayCommitMutation } from 'react-relay'

import getEnv from '@utils/getEnv'
import environment from './environment'

const REACT_APP_HERMIONE_URL = getEnv('REACT_APP_HERMIONE_URL')

export default function commitMutation(config) {
  return relayCommitMutation(environment({ server: REACT_APP_HERMIONE_URL }), config)
}
