/* eslint-disable react/forbid-prop-types */
import * as React from 'react'
import PropTypes from 'prop-types'
import QueryLookupRenderer from 'relay-query-lookup-renderer'
import getEnv from '@utils/getEnv'
import environment from '@relay/environment'

const REACT_APP_HERMIONE_URL = getEnv('REACT_APP_HERMIONE_URL')

if (!REACT_APP_HERMIONE_URL) {
  throw new Error('REACT_APP_HERMIONE_URL must be set for the app to work')
}

const RelayContext = React.createContext({
  relay: {
    environment: environment(REACT_APP_HERMIONE_URL),
    variables: {}
  }
})
RelayContext.displayName = 'RelayContext'

export const renderComponent = (error, component, props, retry, variables) => {
  if (error) return <div>Error: {error.message}</div>

  const WrappedComponent = component // Have to redefine to use properly below

  return (
    <RelayContext.Provider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent loading={!props} retry={retry} variables={variables} {...props} />
    </RelayContext.Provider>
  )
}

function QueryWrapper({ query, component, variables }) {
  return (
    <QueryLookupRenderer
      lookup
      dataFrom="STORE_THEN_NETWORK"
      environment={environment({
        server: REACT_APP_HERMIONE_URL
      })}
      query={query}
      variables={variables}
      render={({ error, props, retry }) => {
        return renderComponent(error, component, props, retry, variables)
      }}
    />
  )
}
QueryWrapper.propTypes = {
  query: PropTypes.func,
  component: PropTypes.func,
  variables: PropTypes.object
}

export default QueryWrapper
