import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import USERTYPES from '@enums/USERTYPES'
import UserTypes from '@types/User.types'
import {
  applyBreakpointMin,
  breakpointMediumAndBelow,
  breakpointSmediumAndBelow,
  breakpointSmallAndBelow,
  breakpointTinyAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import QueryWrapper from '@componentUtils/QueryWrapper'
import { InputContainer } from '@StyledComponents/TextField'
import { StyledTextFieldWrapper } from '@StyledComponents/InputWrapper'
import PageWrapper from '@components/PageWrapper'
import { DisabledInput } from '@components/Form/FormSelect'
import { DebouncedSearchInput } from '@components/Search'
import Pendo from '@lib/Pendo'
import SEOMetaInfo from '@components/SEOMetaInfo'
import TeacherProfileFields from './TeacherProfileFields'
import StudentProfileFields from './StudentProfileFields'

const ProfilePage = styled(PageWrapper)`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: none;
    `
  )}
`

export const ProfileItemsArea = styled.div`
  margin: auto;
  max-width: 755px;
  min-height: inherit;
  width: 100%;
`

const ProfileItems = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;

  ${breakpointSmediumAndBelow(css`
    width: 100%;
    max-width: 688px;
  `)};
  ${breakpointSmallAndBelow(css`
    max-width: 375px;
  `)}
`

const query = graphql`
  query UserProfilePageQuery {
    user {
      ...StudentProfileFields_user
      ...TeacherProfileFields_user
      Type
      Email
      firstName
      graduationYear
      school {
        id
        name
      }
    }
  }
`

export const FormFieldsArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${StyledTextFieldWrapper} {
    justify-content: flex-start;

    ${breakpointMediumAndBelow(
      css`
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        text-align: left;
        width: fit-content;
      `
    )}

    ${InputContainer} {
      width: auto;
    }

    label {
      line-height: 24px;
      width: 300px;
    }
    input {
      padding: ${props => (props.edit ? '12px 8px' : '12px 0')};
      width: 360px;
      ${breakpointSmallAndBelow(
        css`
          width: inherit;
        `
      )}
      ${breakpointTinyAndBelow(
        css`
          width: auto;
        `
      )}
    }
  }

  ${DisabledInput} {
    background: #fff;
  }

  ${DebouncedSearchInput} {
    background: #fff;
  }
`

export function UserProfile({ user, loading }) {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  const [edit, setEdit] = useState(false)

  Pendo.useLoadScript({
    callback: () => Pendo.initialize(user),
    dependencies: { user }
  })

  const getFields = () => {
    if (user.Type === USERTYPES.STUDENT) {
      return <StudentProfileFields user={user} edit={edit} setEdit={setEdit} />
    }
    return <TeacherProfileFields user={user} />
  }

  return (
    <ProfilePage>
      <SEOMetaInfo title="My account | Rate My Professors" />
      <ProfileItemsArea>
        <ProfileItems>
          <FormFieldsArea edit={edit}>{getFields()}</FormFieldsArea>
        </ProfileItems>
      </ProfileItemsArea>
    </ProfilePage>
  )
}

export const UserProfilePage = () => {
  return <QueryWrapper query={query} component={UserProfile} />
}

UserProfile.propTypes = {
  user: UserTypes,
  loading: PropTypes.bool
}

UserProfilePage.serverQueries = [
  {
    query
  }
]

export default UserProfilePage
