/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
let scriptLoaded = false
let historyListener = null

const pageConfigs = [
  {
    regex: /professor\/.*/,
    config: {
      other: 'auto'
    }
  },
  {
    regex: /^\/$/,
    config: {
      home: 'auto'
    }
  },
  {
    regex: /search\/.*/,
    config: {
      other: 'auto'
      // search: 'auto' // Not sure where this came from, but it's not creating the infinite feed
    }
  }
]

const UNIT_TYPE = {
  RAIL_AD: {
    mode: 'thumbnails-rr', // Right Rail
    placement: 'Right Rail Thumbnails',
    target_type: 'mix'
  },
  MID_ARTICLE: {
    mode: 'thumbnails-c',
    placement: 'Mid Article Thumbnails',
    target_type: 'mix'
  },
  BELOW_ARTICLE: {
    mode: 'alternating-thumbnails-a',
    placement: 'Below Article Thumbnails',
    target_type: 'mix'
  },
  BELOW_SEARCH: {
    mode: 'alternating-thumbnails-a',
    placement: 'Below Search Results',
    target_type: 'mix'
  },
  HOME: {
    mode: 'thumbnails-b',
    placement: 'homepage thumbnail',
    target_type: 'mix'
  }
}

/** Pushes a page change operation to taboola, including any page specific config */
function pushPageChange(url, options) {
  window._taboola.push({ notify: 'newPageLoad' })
  window._taboola.push({ ...options, url })
}

/** Gets a config object based on the current page path */
function getConfigOptions(path) {
  const matches = pageConfigs.filter(pc => path.match(pc.regex))
  if (matches.length) {
    return matches[0].config
  }
  return { other: 'auto' }
}

/** passed as a callback to history.listen */
function onRouteChange(location) {
  pushPageChange(location.pathname, getConfigOptions(location.pathname))
}

function loadScript() {
  if (scriptLoaded || typeof document === 'undefined') return
  scriptLoaded = true

  // Copied from Taboola
  window._taboola = window._taboola || []
  window._taboola.push(getConfigOptions(window.location.pathname))
  !(function(e, f, u, i) {
    if (!document.getElementById(i)) {
      e.async = 1
      e.src = u
      e.id = i
      f.parentNode.insertBefore(e, f)
    }
  })(
    document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/cheddar-ratemyprofessor/loader.js',
    'tb_loader_script'
  )
  if (window.performance && typeof window.performance.mark === 'function') {
    window.performance.mark('tbl_ic')
  }
}

function initialize(browserHistory) {
  loadScript()
  if (!historyListener) {
    historyListener = browserHistory.listen(onRouteChange)
  }
}

function loadSlot(unitConfig, containerId) {
  window._taboola = window._taboola || []
  window._taboola.push({
    ...unitConfig,
    container: containerId
  })
}

export default { initialize, loadSlot, UNIT_TYPE }
