import COMPARE_ACTIONS from '@enums/COMPARE'
import ROUTE from '@enums/ROUTE'
import { PENDO_TRACKING_EVENT } from '@enums/TRACKING_EVENT'
import Pendo from '@lib/Pendo'
import isSSR from '@utils/isSSR'
import { matchPath } from 'react-router-dom'

const trimPathParameters = max => {
  const currentURL = new URL(window.location.href)
  const pathSegments = currentURL.pathname.split('/')
  if (pathSegments.length > max) {
    window.history.pushState({}, '', pathSegments.slice(0, max).join('/'))
  }
}

export const handleUrlChange = ({ action, firstProfessor, secondProfessor }) => {
  /**
   * Some links will have UTM params on them, if a professor is changed we
   * want to remove the existing UTM params so we split the url on '?'
   */
  let url = window.location.href.split('?')[0]
  const trimmedUrl = url.endsWith('/') ? url.slice(0, -1) : url
  const pathSegments = trimmedUrl.split('/')
  const primaryIdIndex = pathSegments.indexOf(`${firstProfessor?.legacyId}`)
  const secondaryIdIndex = pathSegments.indexOf(`${secondProfessor?.legacyId}`)

  /**
   * As users change professors we want to index the url by position
   * instead of concatenation
   */
  switch (action) {
    case COMPARE_ACTIONS.SELECT_FIRST_PROFESSOR:
      pathSegments.push(firstProfessor.legacyId)
      break
    case COMPARE_ACTIONS.SELECT_SECOND_PROFESSOR:
      pathSegments.push(secondProfessor.legacyId)
      break
    case COMPARE_ACTIONS.EDIT_FIRST_PROFESSOR:
      if (primaryIdIndex !== -1) {
        pathSegments.splice(primaryIdIndex, 1)
      }
      break
    case COMPARE_ACTIONS.EDIT_SECOND_PROFESSOR:
      if (secondaryIdIndex !== -1) {
        pathSegments.splice(secondaryIdIndex, 1)
      }
      break
    default:
      break
  }

  Pendo.track({
    event: PENDO_TRACKING_EVENT.TRACK_COMPARE_PROFESSORS,
    options: {
      URL: url,
      PRIMARY: firstProfessor ? `${firstProfessor.firstName} ${firstProfessor.lastName}` : null,
      SECONDARY: secondProfessor ? `${secondProfessor.firstName} ${secondProfessor.lastName}` : null
    }
  })

  url = pathSegments.join('/')
  window.history.pushState({}, '', url)
}

export const getVariables = location => {
  const match = matchPath(location.pathname, { path: ROUTE.COMPARE_PROFESSORS })

  /**
   * If there are more than two ids in the url then we want to trim the url and
   * limit the number of path parameters
   */
  if (!isSSR() && window?.location?.href) {
    trimPathParameters(5)
  }

  const primaryId = match?.params?.primaryId
  const secondaryId = match?.params?.secondaryId

  if (primaryId && secondaryId) {
    return {
      primaryId: btoa(`Teacher-${primaryId}`),
      secondaryId: btoa(`Teacher-${secondaryId}`),
      skipPrimary: false,
      skipSecondary: false
    }
  }

  if (primaryId && !secondaryId) {
    return {
      primaryId: btoa(`Teacher-${primaryId}`),
      secondaryId: '',
      skipPrimary: false,
      skipSecondary: true
    }
  }

  return {
    primaryId: '',
    secondaryId: '',
    skipPrimary: true,
    skipSecondary: true
  }
}

export const cleanUrl = ({ base64Id }) => {
  const legacyId = atob(base64Id).split('-')[1]
  const url = window.location.href.split('?')[0]
  const pathSegments = url.split('/')
  const index = pathSegments.indexOf(`${legacyId}`)
  if (index !== -1) {
    pathSegments.splice(index, 1)
    window.history.pushState({}, '', pathSegments.join('/'))
  }
}
