import React from 'react'
import PropTypes from 'prop-types'
import SchoolTypes from '@types/School.types'
import TeacherTypes from '@types/Teacher.types'
import { DEFAULT_FILTER } from '@components/TeacherDepartmentFilter'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'

const SearchResultsHeaderText = ({
  filter,
  query,
  school,
  count,
  schoolTeacherNoMatch,
  successPageData,
  itemType
}) => {
  const filterText = filter && filter.label && filter.label !== DEFAULT_FILTER.label && (
    <span>
      in the <b> {filter.label} </b> department
    </span>
  )

  const teacherResultsText = (
    <h1 data-testid="pagination-header-main-results">
      {count} professor{count > 1 ? 's' : ''}
      {query?.text ? (
        <span>
          {' '}
          with<b> &quot;{query?.text}&quot; </b>in their name{' '}
        </span>
      ) : (
        ' '
      )}
      {filterText}
      {filterText && ' '}
      {school && (
        <span>
          at <b>{schoolTeacherNoMatch ? 'other schools' : school?.name}</b>
        </span>
      )}
    </h1>
  )

  const noMatchText = (
    <div>
      <div>
        No professors with <b>&quot;{query?.text}&quot;</b> in their name {filterText}
        {filterText && ' '}
        {school && (
          <span>
            at <b>{school?.name}</b>
          </span>
        )}
      </div>
      {teacherResultsText}
    </div>
  )

  const schoolResultsText = (
    <h1 data-testid="pagination-header-main-results">
      <b>{count}</b> schools with <b>&quot;{query?.text}&quot;</b> in their name
    </h1>
  )

  const successPageText = (
    <h1 data-testid="pagination-header-main-results">
      Rate {successPageData?.teacher ? 'another' : 'a'} professor at <b>{school?.name}</b>{' '}
      {filterText}
    </h1>
  )

  const generateHeaderText = () => {
    const isTeacherSearch = itemType === SEARCH_ITEM_TYPES.TEACHERS
    if (isTeacherSearch) {
      if (successPageData) return successPageText
      if (schoolTeacherNoMatch) return noMatchText
      return teacherResultsText
    }
    return schoolResultsText
  }

  return <div data-testid="search-results-header">{generateHeaderText()}</div>
}

SearchResultsHeaderText.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    fallback: PropTypes.bool,
    departmentID: PropTypes.string
  }),
  school: SchoolTypes,
  count: PropTypes.number,
  schoolTeacherNoMatch: PropTypes.bool,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ]),
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES))
}

export default SearchResultsHeaderText
