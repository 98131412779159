/* stylelint-disable selector-list-comma-newline-after */

import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import Poppins from '@assets/fonts/Poppins'
import HelpScoutLoader from '@lib/HelpScoutLoader'

const GlobalStyles = createGlobalStyle`
  ${reset}

  /* Import Poppins */
  ${Poppins}

  /* HelpScout */
  ${HelpScoutLoader.styles}

  body, 
  div, 
  a, 
  button {
    color: ${props => props.theme.color.default};
    font-family: ${props => props.theme.fontFamily.helveticaNeue};
    font-size: ${props => props.theme.fontSize.default};
  }

  b {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: black;
  }

  .__react_component_tooltip {
    /* will be displayed by dynamic CSS on JS Load */
    display: none;
  }
`

GlobalStyles.displayName = 'GlobalStyles'

export default GlobalStyles
