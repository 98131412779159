import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { NEW_SEARCH_ICONS, CloseIconWrapper } from '@components/NewSearch'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import {
  breakpointLargeAndBelow,
  breakpointSmediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { DebouncedSearchInput, SearchInputContainer } from '@components/Search'
import SchoolTypes from '@types/School.types'
import { StyledSearchTypeaheadDropdown, StyledScrollContainer } from '@components/SearchTypeahead'
import { StyledLink, NewSearchSchoolName } from '@components/MenuItem'
import SearchContainer from '@components/Search/SearchContainer'
import TeacherSchoolSelectorInput, { StyledSchoolSelectorInput } from './TeacherSchoolSelectorInput'
import { useHeaderContext } from './HeaderContext'

const baseTypeaheadStyles = css`
  ${StyledSearchTypeaheadDropdown} {
    margin: 0 auto;
    max-width: 738px;
    position: absolute;
    top: 34px;
    width: 100%;

    ${NewSearchSchoolName} {
      max-width: unset;
    }

    /* SVGs should not display for MenuItems in Header typeaheads */
    svg {
      display: none;
    }
  }
`

export const baseInputStyles = css`
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 24px;
  padding: 8px 20px;
`

const SchoolInput = styled(SearchContainer)`
  ${baseTypeaheadStyles}

  ${DebouncedSearchInput} {
    ${baseInputStyles}
    padding-left: ${({ hasValue }) => hasValue && '50px'};
    padding-right: 36px;
    width: 100%;

    ${breakpointSmediumAndBelow(css`
      margin-left: 0;
      max-width: unset;
    `)}
  }

  ${StyledSearchTypeaheadDropdown} {
    max-width: 795px;
  }

  ${StyledScrollContainer} {
    width: 100%;
  }
`

const TeacherInput = styled(SearchContainer)`
  ${baseTypeaheadStyles}

  ${DebouncedSearchInput} {
    ${baseInputStyles}
    max-width: 350px;
    padding-left: ${({ hasValue }) => hasValue && '50px'};
    padding-right: 36px;
    width: 100%;

    ${breakpointSmediumAndBelow(css`
      margin: ${({ isMobileInputFullWidth }) => (isMobileInputFullWidth ? '0' : '0 15px')};
      max-width: unset;
    `)}
  }

  ${StyledSearchTypeaheadDropdown} {
    max-width: 350px;

    ${breakpointLargeAndBelow(css`
      ${NewSearchSchoolName} {
        max-width: 200px;
      }

      ${StyledLink} {
        padding: 13px 16px;
      }
    `)}

    ${breakpointSmediumAndBelow(css`
      max-width: 738px;
    `)}

    ${StyledScrollContainer} {
      max-width: 738px;
      width: 100%
    }
  }
`

const TeacherWrapperAt = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.color.white};

  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: 0px 8px;

  ${breakpointSmediumAndBelow(css`
    display: none;
  `)}
`

const TeacherInputWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 746px;

  ${breakpointSmediumAndBelow(css`
    flex-flow: column;
    max-width: unset;
    ${StyledSchoolSelectorInput} {
      display: none;
    }
  `)}
`

const SchoolInputWrapper = styled.div`
  max-width: 350px;

  ${CloseIconWrapper} {
    right: 12px;
  }
`

const SelectorInputContainer = styled.div`
  display: flex;
  width: 100%;

  ${breakpointSmediumAndBelow(css`
    display: none;

    ${TeacherWrapperAt} {
      ${breakpointSmediumAndBelow(css`
        display: block;
        left: 21px;
        padding: 0;
        position: absolute;
        top: 75px;
      `)}
    }
  `)}
`

const SelectorInputWrapper = styled.div`
  text-align: left;
  width: 100%;

  ${breakpointSmediumAndBelow(css`
    display: none;
  `)}

  ${CloseIconWrapper} {
    right: 32px;
  }

  ${SearchInputContainer} {
    max-width: unset;
  }
`

const HeaderSearchInput = ({ school, setSchool }) => {
  const inputRef = useRef(null)
  const [hasValue, setHasValue] = useState(false)

  const [profInputValue, setProfInputValue] = useState('')
  const [schoolInputValue, setSchoolInputValue] = useState('')

  const {
    setIsMobileInputFullWidth,
    isMobileInputFullWidth,
    isSchoolSearch,
    isMobileSearchOpen
  } = useHeaderContext()

  useEffect(() => {
    if (!isMobileSearchOpen) {
      setProfInputValue('')
      setSchoolInputValue('')
    }
  }, [isMobileSearchOpen])

  if (!isSchoolSearch) {
    return (
      <TeacherInputWrapper>
        <TeacherInput
          searchCategory={SEARCH_CATEGORY.TEACHERS}
          schoolID={school?.id}
          hasCloseIcon={profInputValue}
          onFocus={() => setIsMobileInputFullWidth(true)}
          onBlur={() => setIsMobileInputFullWidth(false)}
          isMobileInputFullWidth={isMobileInputFullWidth}
          iconType={NEW_SEARCH_ICONS.TEACHER_RATINGS_PAGE}
          hasValue={hasValue}
          setHasValue={setHasValue}
          onItemSelected={({ setIsOpen }) => {
            // eslint-disable-next-line no-unused-expressions
            inputRef?.current?.blur()
            setIsOpen(false)
          }}
          inputRef={inputRef}
          inputValue={profInputValue}
          setInputValue={setProfInputValue}
          useErrorBorder
        />
        <SelectorInputContainer>
          {school && <TeacherWrapperAt>at</TeacherWrapperAt>}
          <SelectorInputWrapper>
            <TeacherSchoolSelectorInput
              school={school}
              setSchool={setSchool}
              inputValue={schoolInputValue}
              setInputValue={setSchoolInputValue}
            />
          </SelectorInputWrapper>
        </SelectorInputContainer>
      </TeacherInputWrapper>
    )
  }

  return (
    <SchoolInputWrapper>
      <SchoolInput
        searchCategory={SEARCH_CATEGORY.SCHOOLS}
        iconType={NEW_SEARCH_ICONS.TEACHER_RATINGS_PAGE}
        isMobileInputFullWidth={isMobileInputFullWidth}
        inputValue={schoolInputValue}
        setInputValue={setSchoolInputValue}
        hasCloseIcon={schoolInputValue}
        hasValue={hasValue}
        setHasValue={setHasValue}
        useErrorBorder
      />
    </SchoolInputWrapper>
  )
}

HeaderSearchInput.propTypes = {
  school: SchoolTypes,
  setSchool: PropTypes.func
}

export default HeaderSearchInput
