/* eslint-disable react/no-unknown-property */
import React from 'react'
import theme from '@components/StyledComponents/theme'

const ExcoVideoPlayer = () => {
  return (
    <div
      id="583f3bb4-774f-4326-9f0a-7ac11d36f287"
      style={{
        postion: 'static',
        height: 'auto',
        width: 'auto',
        maxWidth: '524px',
        marginTop: '16px',
        marginBottom: '16px',
        zIndex: theme.zIndex.videoAnchor
      }}
    />
  )
}

export default ExcoVideoPlayer
