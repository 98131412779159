import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  getPageContainerMaxWidth,
  breakpointSmediumAndBelow,
  breakpointXMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import ToastContainer from '@components/Toast/ToastContainer'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import MobileMenu, { MobileMenuWrapper } from '@components/MobileMenu'
import UserMenu from '@components/UserMenu'
import ROUTE from '@enums/ROUTE'
import { useLocation } from 'react-router'
import HeaderSearch from './HeaderSearch'
import HeaderLogo from './HeaderLogo'
import HeaderContext from './HeaderContext'
import HeaderCengageBanner from './HeaderCengageBanner'

const HeaderMobileMenuWrapper = styled.div`
  margin-left: 10px;
  order: 1;

  ${MobileMenuWrapper} {
    display: none;

    ${breakpointXMediumAndBelow(css`
      display: block;
    `)}

    ${breakpointSmediumAndBelow(
      css`
        display: none;
        order: 3;
      `
    )}
  }
`

export const StyledHeader = styled.header`
  align-items: center;
  background: black;
  color: white;
  display: flex;
  margin: 0 auto;
  padding: 12px 24px;
  width: 100%;

  ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen &&
    css`
      background: white;
    `}

  ${breakpointSmediumAndBelow(
    css`
      padding: 12px 16px;
    `
  )}
`

const UserMenuWrapper = styled.div`
  position: relative;

  ${breakpointXMediumAndBelow(
    css`
      display: none;
    `
  )}
`

const StyledDummyElement = styled.div`
  order: 3;

  ${breakpointSmediumAndBelow(
    css`
      order: 1;
    `
  )}
`

const HeaderWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => getPageContainerMaxWidth(theme)}
`

const HeaderBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = ({ contextConfig }) => {
  const {
    isSchoolSearch,
    isMobileSearchOpen,
    isMobileMenuOpen,
    setIsMobileMenuOpen
  } = contextConfig

  return (
    <HeaderWrapper>
      <StyledDummyElement />
      <HeaderContext.Provider value={contextConfig}>
        {!isMobileSearchOpen && <HeaderLogo />}
        <HeaderSearch />
        <UserMenuWrapper isSchoolSearch={isSchoolSearch}>
          <UserMenu />
        </UserMenuWrapper>
        <HeaderMobileMenuWrapper isSchoolSearch={isSchoolSearch}>
          <MobileMenu
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </HeaderMobileMenuWrapper>
      </HeaderContext.Provider>
    </HeaderWrapper>
  )
}

export default function WrappedHeader() {
  const location = useLocation()

  const isSchoolSearchRoute = location.pathname.includes(ROUTE.SCHOOL_SEARCH)

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false)
  const [searchCategory, setSearchCategory] = useState(
    isSchoolSearchRoute ? SEARCH_CATEGORY.SCHOOLS : SEARCH_CATEGORY.TEACHERS
  )
  const [isMobileInputFullWidth, setIsMobileInputFullWidth] = useState(false)

  const isSchoolSearch = searchCategory === SEARCH_CATEGORY.SCHOOLS

  const contextConfig = {
    isSchoolSearch,
    searchCategory,
    isMobileMenuOpen,
    isMobileSearchOpen,
    setIsMobileSearchOpen,
    setIsMobileMenuOpen,
    setSearchCategory,
    isMobileInputFullWidth,
    setIsMobileInputFullWidth
  }

  return (
    <HeaderBannerWrapper>
      <StyledHeader isMobileMenuOpen={isMobileMenuOpen} role="banner">
        <ToastContainer limit={1} />
        <Header contextConfig={contextConfig} />
      </StyledHeader>
      <HeaderCengageBanner />
    </HeaderBannerWrapper>
  )
}

Header.propTypes = {
  contextConfig: PropTypes.shape({
    isSchoolSearch: PropTypes.bool,
    searchCategory: PropTypes.oneOf(Object.values(SEARCH_CATEGORY)),
    isMobileMenuOpen: PropTypes.bool,
    isMobileSearchOpen: PropTypes.bool,
    setIsMobileSearchOpen: PropTypes.func,
    setIsMobileMenuOpen: PropTypes.func,
    setSearchCategory: PropTypes.func,
    isMobileInputFullWidth: PropTypes.bool,
    setIsMobileInputFullWidth: PropTypes.func
  })
}
