import React from 'react'
import { graphql } from 'react-relay'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PropTypes from 'prop-types'
import ROUTE from '@enums/ROUTE'
import { withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import TaboolaAd, { INFINITE_TABOOLA_ANCHOR } from '@components/Ads/TaboolaAd'
import TaboolaLoader from '@lib/TaboolaLoader'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SchoolTypes from '@types/School.types'
import sanitizeQuery from '@utils/sanitizeQuery'
import SchoolSearchPagination from './SchoolSearchPagination'

const graphqlQuery = graphql`
  query SchoolSearchResultsPageQuery($query: SchoolSearchQuery!) {
    search: newSearch {
      ...SchoolSearchPagination_search @arguments(count: 8, cursor: "", query: $query)
    }
  }
`

export const SchoolSearchResultsPage = props => {
  const { search, query, successPageData } = props
  return (
    <SchoolSearchPagination
      search={search}
      query={query}
      itemType={SEARCH_ITEM_TYPES.SCHOOLS}
      successPageData={successPageData}
    />
  )
}

const getVariables = location => {
  const params = new URLSearchParams(location.search)
  let queryText = params.get('q') || ''
  if (queryText === '*') queryText = ''

  const queryTextSanitized = sanitizeQuery(queryText)

  return { query: { text: queryTextSanitized || '' } }
}

export const SchoolSearchResultsPageQueryWrapper = ({ location }) => {
  const variables = getVariables(location)
  const successPageData = location.pathname.includes(ROUTE.ADD_RATING_SUCCESS) && location.state

  return (
    <div>
      <QueryWrapper
        query={graphqlQuery}
        variables={variables}
        component={props => (
          <SchoolSearchResultsPage
            query={variables.query}
            successPageData={successPageData}
            {...props}
          />
        )}
      />
      <TaboolaAd id={INFINITE_TABOOLA_ANCHOR} type={TaboolaLoader.UNIT_TYPE.BELOW_ARTICLE} />
    </div>
  )
}

SchoolSearchResultsPage.propTypes = {
  search: PropTypes.shape({
    schools: PropTypes.shape({
      edges: PropTypes.arrayOf({ cursor: PropTypes.string, node: SchoolTypes })
    })
  }),
  query: PropTypes.shape({
    text: PropTypes.string
  }),
  successPageData: PropTypes.shape({
    school: SchoolTypes
  })
}

SchoolSearchResultsPageQueryWrapper.propTypes = {
  location: ReactRouterPropTypes.location
}
const withRouterSchoolSearchResultsPageQueryWrapper = withRouter(
  SchoolSearchResultsPageQueryWrapper
)
withRouterSchoolSearchResultsPageQueryWrapper.serverQueries = [
  {
    query: graphqlQuery,
    getVariables
  }
]

export default withRouterSchoolSearchResultsPageQueryWrapper
