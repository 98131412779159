import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import NoRatingsArea from '@StyledComponents/NoRatingsArea'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import RatingDistributionChart from './RatingDistributionChart'

export const StyledRatingDistributionWrapper = styled.div`
  background-color: ${props => !props.compareProfessors && props.theme.color.gray7};
  height: 325px;
  margin-bottom: 24px;
  width: ${props => (props.compareProfessors ? '364px' : '620px')};
  ${breakpointSmallPlusAndBelow(css`
    width: ${props => (props.compareProfessors ? '164px' : '324px')};
  `)}
`

export const RatingDistributionWrapper = ({ teacher, compareProfessors }) => {
  return (
    <StyledRatingDistributionWrapper compareProfessors={compareProfessors}>
      {teacher.ratingsDistribution && teacher.ratingsDistribution.total ? (
        <RatingDistributionChart
          ratingsDistribution={teacher.ratingsDistribution}
          compareProfessors={compareProfessors}
        />
      ) : (
        <NoRatingsArea
          teacher={teacher}
          trackingLabel={TRACKING_EVENT.PROF_RATE_BUTTON_CENTER}
          gray
        />
      )}
    </StyledRatingDistributionWrapper>
  )
}

RatingDistributionWrapper.propTypes = {
  teacher: TeacherTypes,
  compareProfessors: PropTypes.bool
}

export default createFragmentContainer(RatingDistributionWrapper, {
  teacher: graphql`
    fragment RatingDistributionWrapper_teacher on Teacher {
      ...NoRatingsArea_teacher
      ratingsDistribution {
        total
        ...RatingDistributionChart_ratingsDistribution
      }
    }
  `
})
