import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import TaboolaLoader from '@lib/TaboolaLoader'
import { applyBreakpointMin } from '@StyledComponents/theme/helpers/breakpoints'

export const INFINITE_TABOOLA_ANCHOR = 'taboola-below-article-thumbnails'
export const TABOOLA_AD_TEST_ID = 'test-id-taboola-ad'

const StyledTaboolaAd = styled.div`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: calc(
        ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px
      );
    `
  )}
`

export default function TaboolaAd({ id, type, className }) {
  useEffect(() => {
    TaboolaLoader.loadSlot(type, id)
  }, [id, type])

  return <StyledTaboolaAd id={id} className={className} data-testid={TABOOLA_AD_TEST_ID} />
}

TaboolaAd.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TaboolaLoader.UNIT_TYPE))
}
