import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  breakpointSmediumAndBelow,
  breakpointXLargeAndBelow,
  getPageContainerMaxWidth
} from '@components/StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import StickyHeaderContent from './StickyHeaderContent'

const SCHOOL_HEADER_HEIGHT = 238
export const HEIGHT_TO_TRIGGER_PROF_HEADER = 624

const StyledStickyHeader = styled.div`
  background: white;
  box-shadow: 0px 4px 4px rgba(126, 126, 126, 0.25);
  display: flex;
  align-items: center;
  min-height: ${({ rateTeacher, rateSchool }) => (rateTeacher || rateSchool ? '148px' : '172px')};
  height: 100%;
  min-width: 100%;
  position: ${({ visible }) => (visible ? 'sticky' : 'absolute')};
  top: 64px;
  z-index: ${({ theme }) => theme.zIndex.stickyHeader};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(-20px)')};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  transition: opacity 0.5s ease, transform 0.5s ease;

  ${props =>
    props.school &&
    css`
      min-height: 196px;
    `}

  ${breakpointXLargeAndBelow(css`
    ${props => props.school && `max-height: 232px;`}
  `)}

  ${breakpointSmediumAndBelow(css`
    ${props => props.school && `max-height: 264px;`}
    min-height: ${({ rateTeacher, rateSchool }) => (rateTeacher || rateSchool ? '78px' : '172px')};
  `)}
`

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 34px;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 16px;

  ${({ theme }) => getPageContainerMaxWidth(theme)};

  ${breakpointXLargeAndBelow(css`
    padding-right: 20px;
    padding-left: 24px;
  `)}

  ${breakpointSmediumAndBelow(css`
    min-width: 100%;
  `)}
`

const StickyHeader = ({ teacher, large, school, rateTeacher, rateSchool, showButtons = true }) => {
  const [scrolled, setScrolled] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const headerHeights = school ? SCHOOL_HEADER_HEIGHT : HEIGHT_TO_TRIGGER_PROF_HEADER

    const onScroll = () => {
      setVisible(window.scrollY > headerHeights)
      setScrolled(window.scrollY > headerHeights)
    }
    if (window) window.addEventListener('scroll', onScroll)
    return () => {
      if (window) window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <StyledStickyHeader
      scrolled={scrolled}
      visible={school || rateTeacher || rateSchool || visible}
      school={school}
      rateTeacher={rateTeacher}
      rateSchool={rateSchool}
    >
      <StyledInnerContainer>
        <StickyHeaderContent
          teacher={teacher}
          large={large}
          school={school}
          showButtons={showButtons}
        />
      </StyledInnerContainer>
    </StyledStickyHeader>
  )
}

StickyHeader.propTypes = {
  teacher: TeacherTypes,
  large: PropTypes.bool,
  school: SchoolTypes,
  rateTeacher: PropTypes.bool,
  rateSchool: PropTypes.bool,
  showButtons: PropTypes.bool
}

export default StickyHeader
