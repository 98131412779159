import React, { useRef, useEffect } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import styled, { ThemeProvider } from 'styled-components'
import hashLinkScroll from '@utils/hashLinkScroll'
import theme from '@StyledComponents/theme'
import GlobalStyles from '@StyledComponents/theme/globalStyles'
import GlobalContextProvider, { userInfoQuery } from '@contexts/GlobalContext/GlobalContextProvider'
import HelpScoutLoader from '@lib/HelpScoutLoader'
import TaboolaLoader from '@lib/TaboolaLoader'
import AppMeta from '@components/AppMeta'
import HeaderContainer from '@components/HeaderContainer'
import { generateRouteList } from '@src/config/routeConfig'
import FooterTaboolaWrapper from '@components/Footer/FooterTaboolaWrapper'
import Tooltip from '@components/Tooltip/Tooltip'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@node_modules/react-toastify/dist/ReactToastify.css'
import ScrollToTop from '@utils/ScrollToTop'
import Pendo from '@lib/Pendo'
import AdPixels from '@components/Ads/AdPixels'

const StyledApp = styled.div`
  background-color: white;
  position: relative;
  text-align: center;
`

export const Body = styled.div`
  background: white;
  color: black;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 2rem;
  }

  .Toastify__toast {
    background: transparent;
    box-shadow: none;

    &-container {
      top: 62px;
      width: fit-content;

      /* stylelint-disable */
      &--top-right {
        right: 70px;
      }

      &--top-center {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`

export function App({ history }) {
  const appInitialized = useRef(false)

  /**
   * This is to ensure we are locating the route / component for decoded URI
   */
  useEffect(() => {
    const decoded = decodeURIComponent(window.location.pathname)
    if (decoded !== window.location.pathname) {
      window.location.replace(decoded)
    }
  }, [])

  useEffect(() => history.listen(() => hashLinkScroll(window.location.hash)))

  if (!appInitialized.current) {
    TaboolaLoader.initialize(history)
    HelpScoutLoader.initialize()
    appInitialized.current = true
  }

  Pendo.useLoadScript({
    callback: () => Pendo.initialize()
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider>
        <StyledApp>
          <AppMeta />
          <GlobalStyles />
          <Body>
            <HeaderContainer />
            {!global.isTest && <Tooltip />}
            <ScrollToTop />
            <Switch>{generateRouteList()}</Switch>
            <FooterTaboolaWrapper />
            <AdPixels />
          </Body>
        </StyledApp>
      </GlobalContextProvider>
    </ThemeProvider>
  )
}

const shouldQuery = cookieMap => cookieMap.rmpAuth || cookieMap.userinfo
App.serverQueries = [{ query: userInfoQuery, shouldQuery }]

App.propTypes = {
  history: ReactRouterPropTypes.history
}

export default withRouter(App)
