import { css } from 'styled-components'
import { breakpointMediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import isSSR from '@utils/isSSR'

export const SUPPORT_BEACON_ID = 'ff3edd20-ec14-4747-9845-128d165556f6'
export const CCPA_BEACON_ID = '4f1db2ba-3167-46be-811a-c54fbbb24afe'

let initialized = false

function initialize() {
  if (initialized || isSSR()) return

  /* eslint-disable */
  // prettier-ignore
  !function(e,t,n){function a(){let e=t.getElementsByTagName("script")[0]; var n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],t.readyState==="complete")return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){})
  /* eslint-enable */
  window.Beacon('init', SUPPORT_BEACON_ID)
  initialized = true
}

// This will be added to global styles
const styles = css`
  ${breakpointMediumAndBelow(css`
    #beacon-container .BeaconFabButtonFrame {
      display: none;
    }
  `)}

  #beacon-container {
    .BeaconFabButtonFrame {
      bottom: 105px;
      left: 15px;
    }

    .BeaconFabButtonFrame,
    .BeaconContainer {
      z-index: ${({ theme }) => theme.zIndex.helpScout} !important;
    }

    .BeaconContainer.BeaconContainer.is-configDisplayLeft {
      bottom: 105px;
      left: 90px;
    }
  }
`

function showCCPAForm(text) {
  if (!initialized) return

  window.Beacon('destroy')
  window.Beacon('init', CCPA_BEACON_ID)

  window.Beacon('prefill', { text })
  window.Beacon('navigate', '/ask/message/')
  window.Beacon('open')

  window.Beacon('on', 'close', () => {
    window.Beacon('destroy')
    window.Beacon('init', SUPPORT_BEACON_ID)
  })
}

export default { initialize, styles, showCCPAForm }
