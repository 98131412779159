import React from 'react'
import ROUTE from '@enums/ROUTE'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import TeacherTypes from '@types/Teacher.types'
import RatingTypes from '@types/Rating.types'
import PageWrapper from '@components/PageWrapper'
import parseAndSanitize from '@utils/parseAndSanitize'
import Form from '@components/Form/Form'
import FormCard from '@components/Form/FormCard'
import RatingSlider from '@components/Form/ScaleSlider/RatingSlider'
import RadioButtons from '@components/Form/RadioButtons/RadioButtons'
import {
  getPageContentMaxWidth,
  breakpointLargePlusAndBelow,
  breakpointTinyAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import AdRail from '@components/Ads/AdRail'
import FormTags from '@components/Form/FormTags'
import StickyHeader from '@components/StickyHeader/StickyHeader'
import SEOMetaInfo from '@components/SEOMetaInfo'
import generateCompId from '@utils/generateCompId'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import rateTeacherModel from './RateTeacher.model'
import SubmitArea from '../Sections/SubmitArea'
import GradeDropdown from '../Sections/GradeDropdown'
import WriteReview from '../Sections/WriteReview'
import CourseCode from '../Sections/CourseCode'
import {
  rateTeacherMutation,
  editRateTeacherMutation,
  generateExistingData
} from './RateTeacher.utils'

export const StyledAddRatingTeacherPage = styled(PageWrapper)`
  ${breakpointLargePlusAndBelow(css`
    padding: 0 24px;
  `)}
  ${breakpointTinyAndBelow(css`
    padding: 0;
  `)}
`

export const StyledAddRatingTeacherForm = styled(Form)`
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

export function RateTeacher({ teacherTags, teacher, rating, loading }) {
  const {
    courseCode,
    sliders,
    radioButtons,
    gradeDropdown,
    writeReview,
    allTeacherTags
  } = rateTeacherModel

  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  const history = useHistory()

  const radioToBoolean = value => {
    if (!value) return undefined
    const boolValue = value === 'Yes'
    return boolValue
  }

  const tagToArray = model => {
    const activeTags = []
    teacherTags.forEach(tag => {
      const condensedTag = tag.name.replace(/ /g, '')
      if (model[condensedTag]) {
        activeTags.push(tag.id)
      }
    })
    return activeTags
  }

  const generateGetVariables = model => {
    const getVariablesData = {
      teacherID: teacher.id,
      class: model.class,
      online: model.online ? model.online : false,
      rating: model.rating,
      difficulty: model.difficulty,
      wouldTakeAgain: radioToBoolean(model.wouldTakeAgain),
      forCredit: radioToBoolean(model.forCredit),
      usesTextbooks: radioToBoolean(model.usesTextbooks),
      attendanceMandatory: radioToBoolean(model.attendanceMandatory),
      grade: model.grade,
      comment: parseAndSanitize(model.comment),
      tags: tagToArray(model),
      recaptcha: model.recaptcha,
      compID: generateCompId()
    }

    if (rating?.id) getVariablesData.ID = rating.id
    return getVariablesData
  }

  const isEditRating = !!rating

  return (
    <React.Fragment>
      <SEOMetaInfo
        title={`${isEditRating ? 'Edit your' : 'Add a'} rating for Professor ${
          teacher.lastName
        } at ${teacher.school.name}| Rate My Professors`}
        description={`${isEditRating ? 'Edit your' : 'Add a'} rating for Professor ${
          teacher.lastName
        } at ${teacher.school.name}`}
      />
      <StickyHeader teacher={teacher} school={null} rateTeacher large={false} showButtons={false} />
      <StyledAddRatingTeacherPage>
        <AdLeaderboard code="ad_lb_top_ratings" />
        <StyledAddRatingTeacherForm
          getVariables={model => generateGetVariables(model)}
          existingData={rating && generateExistingData(rating)}
          mutation={rating ? editRateTeacherMutation : rateTeacherMutation}
          onSuccess={() => {
            history.push({
              pathname: ROUTE.ADD_PROFESSOR_RATING_SUCCESS.replace(':sid', teacher.school.legacyId),
              state: { teacher }
            })
          }}
        >
          <FormCard fieldName={courseCode.name} label={courseCode.label} isRequired>
            <CourseCode teacher={teacher} />
          </FormCard>
          {sliders.map(({ name, label, isRequired, type }) => {
            return (
              <FormCard fieldName={name} label={label} isRequired={isRequired} key={name}>
                <RatingSlider type={type} label={label} name={name} />
              </FormCard>
            )
          })}
          {radioButtons.map(({ name, label, isRequired }) => {
            return (
              <FormCard fieldName={name} label={label} isRequired={isRequired} key={name}>
                <RadioButtons name={name} label={label} />
              </FormCard>
            )
          })}
          <FormCard fieldName={gradeDropdown.name} label={gradeDropdown.label}>
            <GradeDropdown />
          </FormCard>
          <FormCard fieldName={allTeacherTags.name} label={allTeacherTags.label}>
            <FormTags teacherTags={teacherTags} name={allTeacherTags.name} />
          </FormCard>
          <FormCard
            fieldName={writeReview.name}
            label={writeReview.label}
            subTitle={writeReview.subTitle}
            isRequired
          >
            <WriteReview
              name={writeReview.name}
              guidelines={writeReview.guidelines}
              placeholder={writeReview.placeholder}
            />
          </FormCard>
          <FormCard>
            <SubmitArea isEditRating={isEditRating} isTeacherRating />
          </FormCard>
        </StyledAddRatingTeacherForm>
        <AdRail />
      </StyledAddRatingTeacherPage>
    </React.Fragment>
  )
}

RateTeacher.propTypes = {
  teacherTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.id,
      name: PropTypes.string
    })
  ),
  teacher: TeacherTypes,
  loading: PropTypes.bool,
  rating: RatingTypes
}

export default withRouter(RateTeacher)
