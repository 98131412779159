import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { breakpointLargePlusAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import HeaderDescription from '@components/StickyHeader/HeaderDescription'
import { createFragmentContainer, graphql } from 'react-relay'

const StyledLeftColumn = styled.div`
  width: 100%;
  text-align: left;
`

const StyledRightColumn = styled.div`
  width: 100%;
  text-align: right;

  ${breakpointLargePlusAndBelow(css`
    display: none;
  `)}
`

const StickyHeaderContent = ({ teacher, large, school, showButtons = true }) => {
  return (
    <React.Fragment>
      <StyledLeftColumn>
        {(teacher || school) && (
          <HeaderDescription
            teacher={teacher}
            large={large}
            school={school}
            showButtons={showButtons}
          />
        )}
      </StyledLeftColumn>
      <StyledRightColumn>
        <AdLeaderboard code="ad_lb_top_ratings" schoolHeader />
      </StyledRightColumn>
    </React.Fragment>
  )
}

StickyHeaderContent.propTypes = {
  teacher: TeacherTypes,
  large: PropTypes.bool,
  school: SchoolTypes,
  showButtons: PropTypes.bool
}

export default createFragmentContainer(StickyHeaderContent, {
  teacher: graphql`
    fragment StickyHeaderContent_teacher on Teacher {
      ...HeaderDescription_teacher
      ...HeaderRateButton_teacher
    }
  `,
  school: graphql`
    fragment StickyHeaderContent_school on School {
      ...HeaderDescription_school
      ...HeaderRateButton_school
    }
  `
})
