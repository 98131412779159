import { Environment } from 'relay-runtime'
import getEnv from '@utils/getEnv'
import network from './network'
import RelayStore from '../components/RelayStore'

const REACT_APP_GRAPHQL_AUTH = getEnv('REACT_APP_GRAPHQL_AUTH')
if (!REACT_APP_GRAPHQL_AUTH) {
  throw new Error('REACT_APP_GRAPHQL_AUTH environment variable not set')
}

export default config => {
  return new Environment({
    network: network({ ...config, basicAuthToken: REACT_APP_GRAPHQL_AUTH }),
    store: config.store || RelayStore.store
  })
}
