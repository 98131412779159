import React from 'react'
import styled, { css } from 'styled-components'
import TaboolaAd from '@components/Ads/TaboolaAd'
import { breakpointXMediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import TaboolaLoader from '@lib/TaboolaLoader'
import AdNoBid from '@components/Ads/NoBid/AdNoBid'

const StyledHomepageMidAds = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  width: 100%;

  ${breakpointXMediumAndBelow(css`
    align-items: center;
    flex-direction: column;
  `)}
`

const TaboolaRec = styled(TaboolaAd)`
  /* Taboola applies inline styling we need to override, thus the !important, please forgive me. */
  margin-left: 34px;
  max-width: 90vw;
  width: ${({ theme }) => theme.homeAds.width};

  ${breakpointXMediumAndBelow(css`
    margin: 0px !important;
    margin-top: 10px !important;
  `)}

  .trc_rbox_outer {
    margin-left: 0 !important;
    max-width: 100%;
  }

  .thumbnails-b.trc_rbox {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .trc_spotlight_item {
    height: ${({ theme }) => theme.homeAds.height} !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: ${({ theme }) => theme.homeAds.width} !important;
  }
`

export default function HomepageMidAds() {
  return (
    <StyledHomepageMidAds>
      <AdNoBid code="ad_rec_01_home" />
      <TaboolaRec type={TaboolaLoader.UNIT_TYPE.HOME} id="taboola-homepage-thumbnail" />
    </StyledHomepageMidAds>
  )
}
