/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NEW_SEARCH_ICONS } from '@components/NewSearch'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import {
  breakpointLargeAndBelow,
  breakpointSmediumAndBelow,
  breakpointXMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { DebouncedSearchInput } from '@components/Search'
import SchoolTypes from '@types/School.types'
import { StyledSearchTypeaheadDropdown, StyledScrollContainer } from '@components/SearchTypeahead'
import { StyledLink, NewSearchSchoolName } from '@components/MenuItem'
import Button from '@components/Button'
import WhitePencilIcon from '@assets/images/white_pencil.svg'
import SearchContainer from '@components/Search/SearchContainer'
import { useHeaderContext } from './HeaderContext'

export const StyledSchoolSelectorInput = styled(SearchContainer)`
  ${DebouncedSearchInput} {
    font-size: ${({ theme }) => theme.fontSize.medium};
    line-height: 24px;
    margin-left: 0;
    max-width: 350px;
    padding: 8px 20px;
    padding-left: ${({ hasValue }) => hasValue && '50px'};
    padding-right: 36px;
    width: 100%;
  }

 

  ${StyledSearchTypeaheadDropdown} {
    margin: 0 auto;
    max-width: 738px;
    position: absolute;
    top: 34px;
    width: 100%;


    ${breakpointLargeAndBelow(css`
      ${StyledLink} {
        padding: 13px 16px;
      }
    `)}
    
    ${breakpointXMediumAndBelow(css`
      max-width: 262px;
    `)}

    ${breakpointSmediumAndBelow(css`
      max-width: 738px;
    `)}

    /* stylelint-disable selector-list-comma-newline-after */
    ${NewSearchSchoolName} {
      display: none;
    }


    ${StyledScrollContainer} {
      max-width: 738px;
      width: 100%;
    }

    svg {
      display: none;
    }

  }
`

const SchoolTextNameWrapper = styled(Button)`
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: none;

  img {
    height: 18px;
    margin-left: 15px;
    width: 18px;
  }
`

const SelectedSchoolTextName = styled.div`
  color: white;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  max-width: 228px;
  padding: 0;
  text-decoration: underline;
  text-overflow: ellipsis;

  ${breakpointXMediumAndBelow(css`
    max-width: 183px;
  `)}

  ${breakpointSmediumAndBelow(css`
    max-width: 300px;
  `)}
`

const TeacherSchoolSelectorInput = ({
  school,
  setSchool = () => {},
  onFocus = () => {},
  onBlur = () => {},
  inputValue,
  setInputValue
}) => {
  const hasSchool = school?.legacyId && school?.name
  const [hasValue, setHasValue] = useState(false)
  const [isInputMode, setIsInputMode] = useState(!hasSchool)
  const inputRef = useRef(null)

  const { isMobileSearchOpen } = useHeaderContext()

  // if the user closes the mobile search, we want the value / query to empty
  useEffect(() => {
    if (!isMobileSearchOpen && inputValue) {
      setInputValue('')
    }
  }, [isMobileSearchOpen])

  useEffect(() => {
    setIsInputMode(false)
  }, [school])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    inputRef?.current?.select()
  }, [inputRef.current])

  const onInputBlur = event => {
    setIsInputMode(false)
    onBlur(event)
  }

  const onInputFocus = event => {
    onFocus(event)
  }

  if (!isInputMode && school?.id) {
    return (
      <SchoolTextNameWrapper onClick={() => setIsInputMode(true)}>
        <SelectedSchoolTextName>{school.name}</SelectedSchoolTextName>
        <img src={WhitePencilIcon} alt="Pencil Icon" />
      </SchoolTextNameWrapper>
    )
  }

  return (
    <StyledSchoolSelectorInput
      searchCategory={SEARCH_CATEGORY.SCHOOLS}
      iconType={NEW_SEARCH_ICONS.TEACHER_RATINGS_PAGE}
      setHasValue={setHasValue}
      hasValue={hasValue}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      hasCloseIcon={inputValue}
      inputRef={inputRef}
      allowLink
      onItemSelected={({ event, item }) => {
        event.preventDefault()

        setSchool(item)
      }}
      inputValue={inputValue}
      setInputValue={setInputValue}
      useErrorBorder
    />
  )
}

TeacherSchoolSelectorInput.propTypes = {
  school: SchoolTypes,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  setSchool: PropTypes.func,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func
}

export default TeacherSchoolSelectorInput
