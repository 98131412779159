import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ROUTE from '@enums/ROUTE'
import { applyBreakpointMin } from '@StyledComponents/theme/helpers/breakpoints'
import PageWrapper from '@components/PageWrapper'
import HomepageBkgds from '@pages/Homepage/HomepageBkgds'
import HomepageMidAds from '@pages/Homepage/HomepageMidAds'
import HomepageHero from '@pages/Homepage/HomepageHero'
import HomepageCTA from '@pages/Homepage/HomepageCTA'
import toast, { TOAST_EVENTS } from '@utils/toast'
import EmailConfirmationReminderToast from '@components/Toast/EmailConfirmationReminderToast'
import AccountDeletedToast from '@components/Toast/AccountDeletedToast'
import LoggedOutToast from '@components/Toast/LoggedOutToast'
import ReactRouterPropTypes from 'react-router-prop-types'
import useUserInfo from '@hooks/useUserInfo'
import useCurrentRoute from '@hooks/useCurrentRoute'
import useGlobalContext from '@hooks/useGlobalContext'
import Pendo from '@lib/Pendo'
import AdController from '@components/Ads/AdController'
import AdLeaderboard from '@components/Ads/AdLeaderboard'

const StyledHomepage = styled(PageWrapper)`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: none;
    `
  )}
`

const HomepageMain = styled.div`
  overflow: hidden;
  position: relative;
`

const HomepageMainContent = styled.div`
  margin-bottom: 80px;
  position: relative;
  z-index: ${props => props.theme.zIndex.search};
`

export function Homepage({ loading, location }) {
  const locationState = location?.state
  const historyToastEvent = location?.state?.toast
  const currentRoute = useCurrentRoute()
  const { logout } = useGlobalContext()
  const history = useHistory()
  const userInfo = useUserInfo()

  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  useEffect(() => {
    if (currentRoute.path === ROUTE.NEW_LOGOUT) {
      logout()
      history.push({ pathname: ROUTE.HOME, state: { toast: null } })
      toast(LoggedOutToast, TOAST_EVENTS.LOGGED_OUT)
    } else if (
      (!locationState || historyToastEvent === TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER) &&
      userInfo &&
      !userInfo.emailConfirmed
    ) {
      // Display the email confirmation toast if toast has not been set (on page load),
      // Or if user has logged in during the session
      // And user email is unconfirmed.
      toast(EmailConfirmationReminderToast, TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER)
    }
  })

  useEffect(() => {
    if (
      (!historyToastEvent || historyToastEvent === TOAST_EVENTS.ACCOUNT_DELETED) &&
      locationState &&
      userInfo
    ) {
      if (historyToastEvent === TOAST_EVENTS.ACCOUNT_DELETED) {
        toast(AccountDeletedToast, TOAST_EVENTS.ACCOUNT_DELETED)
        return
      }
      toast(LoggedOutToast, TOAST_EVENTS.LOGGED_OUT)
    }
  }, [locationState])

  Pendo.useLoadScript({
    callback: () => Pendo.initialize(userInfo),
    dependencies: { user: userInfo }
  })

  return (
    <StyledHomepage>
      <HomepageHero />
      <HomepageMain>
        <HomepageMainContent>
          <AdController margin={48}>
            <HomepageMidAds />
          </AdController>
          <HomepageCTA />
          <AdLeaderboard code="ad_lb_home" center />
        </HomepageMainContent>
        <HomepageBkgds />
      </HomepageMain>
    </StyledHomepage>
  )
}

Homepage.propTypes = {
  loading: PropTypes.bool,
  location: ReactRouterPropTypes.location
}

export default Homepage
