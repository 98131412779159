import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import TeacherTypes from '@types/Teacher.types'
import TeacherCard, { StyledTeacherCard } from '@components/TeacherCard/TeacherCard'
import SchoolCard, { StyledSchoolCard } from '@components/SchoolCard/SchoolCard'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SchoolTypes from '@types/School.types'
import AdRail from '@components/Ads/AdRail'
import AddItemCTA from '@StyledComponents/AddItemCTA'
import RelayTypes from '@types/Relay.types'
import PaginationButton, { StyledPaginationButton } from '@StyledComponents/PaginationButton'
import NoResultsFoundArea from '@components/NoResultsFoundArea'
import { breakpointLargeAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TeacherFilterSet from '@components/TeacherFilterSet'
import AdNoBid from '@components/Ads/NoBid/AdNoBid'
import RatingSuccessHeader from '@pages/AddRating/RateTeacher/RatingSuccessHeader'
import SEOMetaInfo from '@components/SEOMetaInfo'
import AdController from '@components/Ads/AdController'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import SearchResultsHeaderText from './SearchResultsHeaderText'

const AD_TOP_SEARCH_PAGE = 120
const AD_INTERVAL = 3

const StyledSearchResultsPage = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  min-height: 100vh;
  width: 100%;

  ${breakpointLargeAndBelow(
    css`
      max-width: unset;
    `
  )}

  ${StyledPaginationButton} {
    margin: 0 auto 24px auto;
    width: 240px;
  }
`

const SearchResultsWrapper = styled.div`
  margin: 0 0 48px 39px;
  max-width: 860px;

  ${breakpointLargeAndBelow(
    css`
      max-width: unset;
      margin: 0 39px 48px 39px;
    `
  )}

  ${StyledTeacherCard},
  ${StyledSchoolCard} {
    margin-bottom: 24px;
  }
`

const AddPromptWrapper = styled.div`
  margin: 0 auto;
  width: 255px;
`

const SearchResultsPageHeader = styled.div`
  div {
    font-size: ${({ theme }) => theme.fontSize.medium};

    margin-bottom: 15px;
    margin-top: 32px;
    text-align: left;
    width: fit-content;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSize.teacherNotes};
    margin-bottom: 48px;
    text-align: left;
  }
`

const getItemCards = (areItemsTeachers, results) => {
  const list = []
  let adIndex = 0
  results.forEach((item, index) => {
    if (index !== 0 && index % AD_INTERVAL === 0) {
      const adUnitKey = `search-results-${adIndex}`
      list.push(
        <AdController>
          <AdNoBid code="ad_lb_dyn_profile" key={adUnitKey} />
        </AdController>
      )
      adIndex += 1
    }
    if (areItemsTeachers) {
      list.push(<TeacherCard teacher={item.node} key={item.cursor} />)
    } else {
      list.push(<SchoolCard school={item.node} key={item.cursor} />)
    }
  })

  return list
}

const getMetaText = (areItemsTeachers, school) => {
  if (!school) {
    return {
      metaTitle: areItemsTeachers
        ? `Search professors | Rate My Professors`
        : `Search schools | Rate My Professors`,
      metaDescription: areItemsTeachers ? `Search professors` : `Search schools`
    }
  }

  return {
    metaTitle: areItemsTeachers
      ? `Search professors at ${school.name ?? null} | Rate My Professors`
      : `Search schools | Rate My Professors`,
    metaDescription: areItemsTeachers ? `Search professors at ${school.name}` : `Search schools`
  }
}

const SearchResults = props => {
  const {
    results,
    loadMore,
    relay,
    isLoading,
    itemType,
    filters,
    filter,
    setFilter,
    successPageData,
    count,
    query,
    school,
    schoolTeacherNoMatch
  } = props

  const areItemsTeachers = itemType === SEARCH_ITEM_TYPES.TEACHERS

  const { metaTitle, metaDescription } = getMetaText(areItemsTeachers, school)

  return (
    <React.Fragment>
      <SEOMetaInfo title={metaTitle} description={metaDescription} />
      {successPageData && <RatingSuccessHeader successPageData={successPageData} />}
      <SearchResultsPageHeader>
        <SearchResultsHeaderText
          filter={filter}
          query={query}
          school={school}
          count={count}
          itemType={itemType}
          schoolTeacherNoMatch={schoolTeacherNoMatch}
          successPageData={successPageData}
        />
      </SearchResultsPageHeader>
      {areItemsTeachers && filters && (
        <TeacherFilterSet
          setFilter={setFilter}
          filter={filter}
          filters={filters}
          options={filters?.options}
        />
      )}
      <div>{getItemCards(areItemsTeachers, results)}</div>
      <AddPromptWrapper>
        <PaginationButton
          onClick={loadMore}
          hasMore={relay.hasMore()}
          isLoading={isLoading}
          role="button"
        >
          Show More
        </PaginationButton>
        <AddItemCTA itemType={itemType} />
      </AddPromptWrapper>
    </React.Fragment>
  )
}

const SearchResultsPage = ({ results, itemType, query, successPageData, ...props }) => {
  const hasNoResults = !results || !results.length > 0 || !itemType

  return (
    <StyledSearchResultsPage>
      <SearchResultsWrapper>
        {!hasNoResults && <AdLeaderboard code="ad_lb_top_search" />}
        {hasNoResults && <NoResultsFoundArea itemType={itemType} queryValue={query.text} />}
        {!hasNoResults && (
          <SearchResults
            results={results}
            itemType={itemType}
            query={query}
            successPageData={successPageData}
            {...props}
          />
        )}
      </SearchResultsWrapper>
      {!hasNoResults && <AdRail adTop={AD_TOP_SEARCH_PAGE} />}
    </StyledSearchResultsPage>
  )
}

const filterPropTypes = PropTypes.shape({
  field: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ count: PropTypes.number, value: PropTypes.string }))
})

SearchResultsPage.propTypes = {
  results: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: TeacherTypes })),
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: SchoolTypes }))
  ]),
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string
  }),
  school: SchoolTypes,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ])
}

SearchResults.propTypes = {
  results: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: TeacherTypes })),
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: SchoolTypes }))
  ]),
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  loadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  relay: RelayTypes,
  filters: PropTypes.arrayOf(filterPropTypes),
  filter: filterPropTypes,
  setFilter: PropTypes.func,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ]),
  count: PropTypes.number,
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    fallback: PropTypes.bool,
    departmentID: PropTypes.string
  }),
  school: SchoolTypes,
  schoolTeacherNoMatch: PropTypes.bool
}

export default SearchResultsPage
